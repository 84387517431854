import * as React from 'react';
import Image from 'next/image';
import { FalconUIRenderingContext, SourceLanguage, Link, Menu } from '@nutmeglabs/falcon-ui';
import { ReduxState } from 'ducks';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useQueryString } from 'hooks/useQueryString';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { appendQueryString } from 'lib/util/appendQueryString';
import { languageOptionMap } from 'lib/util/i18nHelpers';
import { selectIsPMP } from 'lib/util/privateMarketplace';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSubscriptionEnabled } from 'hooks/useSubscriptionEnabled';
import { getFalconUIImageUrl, getImageUrl } from 'lib/util/imageUrl';
import { quantize } from 'lib/util/quantize';

const ImageComponent = ({ src }: { src: string }) => {
  return <Image fill src={src} loader={({ src, width }) => getImageUrl(src, width)} alt="banner" />;
};

interface Props {
  children: React.ReactNode;
}

export const FalconUIContextProvider = ({ children }: Props) => {
  const { t, i18n } = useTranslation();
  const currencyFormatter = useCurrencyFormatter();
  const { accessToken } = useCustomerToken();

  const guestMyPageEnabled = useSubscriptionEnabled('feature-guest-my-page');

  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedLanguage = Object.entries(languageOptionMap).find(([key, value]) => {
    return value.iso === i18n.language;
  })?.[0] as SourceLanguage;

  const languageOptions = useSelector((state: ReduxState) => {
    return state.server.settings.all.supported_languages?.map((lang) => ({
      value: lang,
      text: languageOptionMap[lang].name,
    }));
  });
  const isPMP = useSelector(selectIsPMP);

  const handleLanguageChange = React.useCallback(
    (lang: SourceLanguage) => {
      if (isPMP) {
        const pmpLink = settings.private_marketplace_links?.find((link) => link.language === lang);
        if (pmpLink) {
          window.location.href = pmpLink.url;
        } else {
          const urlParts = window.location.href.split('?');
          const searchParams = new URLSearchParams(urlParts.length > 1 ? urlParts[1] : '');
          searchParams.set('lng', languageOptionMap[lang].iso);

          window.location.href = `https://${
            settings.booking_widget_domain
          }?${searchParams.toString()}`;
        }
      } else {
        const urlParts = window.location.href.split('?');
        const searchParams = new URLSearchParams(urlParts.length > 1 ? urlParts[1] : '');
        searchParams.set('lng', languageOptionMap[lang].iso);

        window.location.href = `${urlParts[0]}?${searchParams.toString()}`;
      }
    },
    [isPMP, settings]
  );

  const queryString = useQueryString();

  return (
    <FalconUIRenderingContext.Provider
      value={{
        companyName: settings.supplier_name || '',
        companyLogoUrl: settings.logo_url || '',
        language: selectedLanguage,
        setLanguage: handleLanguageChange,
        supportedLanguages: languageOptions,
        t: (key: string, args?: Record<string, string>) => {
          switch (key) {
            case 'Sun':
              return t('Sun');
            case 'Mon':
              return t('Mon');
            case 'Tue':
              return t('Tue');
            case 'Wed':
              return t('Wed');
            case 'Thu':
              return t('Thu');
            case 'Fri':
              return t('Fri');
            case 'Sat':
              return t('Sat');
            case 'Days of Week: {{daysOfWeek}}':
              return t('Days of Week: {{daysOfWeek}}', args);
            case 'Hours: {{operatingHours}}':
              return t('Hours: {{operatingHours}}', args);
            case '{{discount}} OFF':
              return t('{{discount}} OFF', args);
          }

          return t(key);
        },
        formatCurrencyAmount: currencyFormatter.format,

        linkFormatter: (link?: Link): string => {
          if (!link) {
            return '';
          }
          switch (link.type) {
            case 'PRODUCT':
              return isPMP
                ? appendQueryString(`/top/products/${link.productId}`, queryString)
                : appendQueryString(`/products/${link.productId}`, queryString);
            case 'CUSTOM_PAGE':
              return appendQueryString(link.customPagePath || '', queryString);
            case 'FULL_URL':
              return link.fullUrl || '';
            case 'PRIVATE_MARKETPLACE':
              return appendQueryString(link.privateMarketplacePath || '', queryString);
            case 'LOGIN_PAGE':
              return appendQueryString(accessToken ? '/me/top' : '/me/signin', queryString);
            default:
              return '';
          }
        },
        getMenuTitle: (menu: Menu): string => {
          if (menu.link?.type === 'LOGIN_PAGE') {
            if (accessToken) {
              return t('My Page');
            } else if (guestMyPageEnabled) {
              return t('Login') + '/' + t('Reservations');
            } else {
              return t('Login');
            }
          }
          return menu.title;
        },
        Image: ImageComponent,
        getImageUrl: ({ src, width, height }: { src: string; width?: number; height?: number }) => {
          return getFalconUIImageUrl({
            src,
            width: width ? quantize(width * 1.5, [2000, 1600, 1200, 800]) : undefined,
            height: height ? quantize(height * 1.5, [2000, 1600, 1200, 800]) : undefined,
          });
        },
      }}
    >
      {children}
    </FalconUIRenderingContext.Provider>
  );
};
